import React from "react";
import styled from "styled-components";
import ArticleCards from "components/molecures/ArticleCards";
import { Link } from "gatsby";
type Props = {
  tag?: any;
  category?: any;
  showTitle?: boolean;
  articles: any;
  limit?: number;
};

const Title = styled.h2`
  color: #000000;
  font-size: 32px;
  padding-bottom: 8px;
  padding-top: 8px;
  width: 100%;
  font-weight: 800;
  opacity: 0.9;
`;

const defaultProps = {
  showTitle: true,
};

export default function ArticlesBox(props: Props) {
  const { category, showTitle, limit, tag, articles } = props;
  // const [articles, setArticles] = React.useState<Article[]>([]);

  // const data = useStaticQuery(graphql`
  //   {
  //     appsync($base: String!) {
  //       searchArticles(filter: {categoryID: {eq: "${category.id}"}, sort: {direction: desc, field: startDateTime}) {
  //         items {
  //           mainImage {
  //             url
  //             name
  //           }
  //           adID
  //           authorID
  //           categoryID
  //           createdAt
  //           description
  //           endDateTime
  //           id
  //           slug
  //           startDateTime
  //           status
  //           title
  //           updatedAt
  //           version
  //         }
  //       }
  //     }
  //   }
  // `);
  // articles = data.appsync.searchArticles.items;

  return (
    <>
      {showTitle && !tag && (
        <Title>{category ? category.name : "新着記事"}</Title>
      )}
      {showTitle && tag && <Title>{tag.name}</Title>}
      <ArticleCards
        articles={articles}
        showCategory={category ? false : true}
      />
      {limit && category && (
        <Link to={`/categories/${category.slug}`}>
          {category.name} をもっと読む
        </Link>
      )}
    </>
  );
}

ArticlesBox.defaultProps = defaultProps;
